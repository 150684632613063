import { createFeature, createReducer, on } from '@ngrx/store';
import { ScrollActions } from './scroll.actions';

export interface ScrollState {
  directionScroll: string;
}

const initialState: ScrollState = {
  directionScroll: 'up'
};

export const scrollFeature = createFeature({
  name: 'scroll',
  reducer: createReducer(
    initialState,
    on(ScrollActions.setDirection, (state, { direction }) => ({
      ...state,
      directionScroll: direction
    }))
  )
});

const { name, reducer, ...selectors } = scrollFeature;
export const ScrollSelectors = selectors;
